.tooltip {
  position: absolute;
  top: -27px;
  left: 75px;
}

.asset-selector {
  position: relative;
}

.device-icons-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.rendition-device-icons-container {
  position: absolute;
  top: 5px;
  right: 5px;
}

.react-icons {
  padding-top: 5px;
}

.desktop-icon {
  color: #cc4700;
}

.tablet-icon {
  color: #007a3b;
}

.mobile-icon {
  color: #003dcc;
}

.rendition-react-icons {
  padding-top: 3px;
  padding-left: 1px;
}

.paste-icon {
  size: 20px;
}

.asset-button {
  min-width: fit-content !important;
}

.orWrapper {
  position: relative;
  width: 100px;
  height: 40px;
}

.line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #aaa;
  z-index: 1;
}

.wordwrapper {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -10px;
  z-index: 2;
}

.word {
  color: #aaa;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial,sans-serif;
  background: #fff;
}

.url-wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.url-input {
  border-radius: 6px 0px 0px 6px !important;
}

.paste-error-msg {
  padding-left: 230px;
}

.paste-button {
  padding: 5px 10px;
  border-radius: 0px 6px 6px 0px !important;
}

.language-group {
  flex-direction: row !important;
  gap: 25px;
}

.thumbnailButton {
  width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0;
  border: none;
}

.thumbnailButton.selected {
  border: 2px solid #40A0FF;
}

.thumbnailButton:hover {
}

.accordion {
  padding-bottom: 25px !important;
}

.accordion-item {
  border: 2px solid #CEECFF;
}

.accordion-item > h2 > button, .accordion-item > h2 > button:focus, .accordion-item > h2 > button:hover {
  background-color: #CEECFF;
}

.accordion-item > div {
  padding: 0 25px;
}

.delete-button {
  padding: 0 !important;
  min-height: auto !important;
}

.asset-header {
  padding-top: 15px;
}

.asset-wrapper {
  padding: 30px 0px;
}

.asset-container {
  width: 450px;
  position: relative;
}

.asset-device-icon {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  text-align: center;
}

.rendition-device-icon {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  text-align: center;
}

.desktop-icon-container {
  background-color: #fbb360;
}

.tablet-icon-container {
  background-color: #affdd5;
}

.mobile-icon-container {
  background-color: #dbe6ff;
}

.device-selector {
  padding-left: 25px;
}

.deviceRadioGroup {
  padding-top: 20px;
}

.deviceCheckboxGroup {
  padding-top: 20px;
  padding-left: 20px;
}

.hidden {
  visibility: hidden;
}

.andthisis {
  text-align: left !important;
}

.published-message-container {
  padding: 20px 0;
}

.published-message {
  color: #008539 !important;
  align-self: center;
}

.unpublished-message {
  padding-left: 25px;
  padding-right: 15px;
  color: #DA294A;
}

.unpublished-link {
  padding-left: 15px !important;
  padding-right: 25px !important;
  align-items: baseline !important;
}

.duplicate-message {
  padding-left: 5px;
  padding-right: 5px;
}

.duplicate-message.error {
  color: #F07F23;
}

.duplicate-message.success {
  color: #008539;
}

.duplicate-link {
  align-items: baseline !important;
}

.contentful-field {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 29px;
  margin-top: 19px;
  padding-left: 1rem;
  border-left: 3px solid #CFD9E0;
}
