.constrain-width {
  max-width: 768px;
  margin: 0 auto;
}

.note {
  margin-bottom: 10px;
}

.asset-img {
  width: 100%;
}

.asset-video {
  width: 100%;
}

.asset-display {
  display: flex;
}

.asset-preview {
  overflow: hidden;
  flex: 0 0 300px;
  padding-top: 5px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: start;
}

.asset-details {
  flex: 1 0 auto;
}

.asset-details form {
  padding-right: 10px;
}

.action-button {
  margin-right: 10px;
}

.app-config-page {
  max-width: 900px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
}

.unpublished-asset-icon {
  align-self: center;
}

.extended-meta-form {
  display: flex;
  margin-right: -10px;
}

.extended-meta-form > * {
  flex: 1 1 50%;
  margin-right: 10px;
}

.s7videoviewer .s7fullscreenbutton {
  display: none;
}

.validation-message {
  display: flex;
}

.validation-message > svg {
  padding-right: 5px;
}
